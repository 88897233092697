import axios from 'axios';

export const fetchStockPrice = async (symbol) => {
    try {
        const { data } = await axios.get('https://www.alphavantage.co/query', {
            params: {
                function: 'GLOBAL_QUOTE',
                symbol: symbol,
                apikey: '7XOVO2C7YC738UCM'
            }
        });

        const globalQuote = data['Global Quote'];
        const latestPrice = parseFloat(globalQuote['05. price']);

        return latestPrice;
    } catch (error) {
        console.error('Error fetching stock price:', error);
        return 'Error fetching price';
    }
};

export const fetchHistoricalData = async (symbol, setHistoricalData) => {
    try {
        const { data } = await axios.get('https://www.alphavantage.co/query', {
            params: {
                function: 'TIME_SERIES_DAILY',
                symbol: symbol,
                apikey: '7XOVO2C7YC738UCM'
            }
        });

        const timeSeries = data['Time Series (Daily)'];
        const dates = Object.keys(timeSeries).slice(0, 22).reverse();
        const prices = dates.map(date => timeSeries[date]['4. close']);

        setHistoricalData({
            labels: dates,
            datasets: [
                {
                    label: `${symbol} Price`,
                    data: prices,
                    fill: false,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                },
            ],
        });
    } catch (error) {
        console.error('Error fetching historical data:', error);
        setHistoricalData(null);
    }
};

export const fetchCompanyOverview = async (symbol) => {
    try {
        const { data } = await axios.get('https://www.alphavantage.co/query', {
            params: {
                function: 'OVERVIEW',
                symbol: symbol,
                apikey: '7XOVO2C7YC738UCM'
            }
        });

        return data;
    } catch (error) {
        console.error('Error fetching company overview:', error);
        return null;
    }
};

export const fetchOptionsData = async (symbol, setOptionsData, setStrikes, setExpiries) => {
    try {
        const { data } = await axios.get('https://www.alphavantage.co/query', {
            params: {
                function: 'REALTIME_OPTIONS',
                symbol: symbol,
                apikey: '7XOVO2C7YC738UCM'
            }
        });

        const sortedOptions = data.data.sort((a, b) => parseFloat(a.strike) - parseFloat(b.strike));
        const uniqueStrikes = [...new Set(sortedOptions.map(option => option.strike))];
        const uniqueExpiries = [...new Set(sortedOptions.map(option => option.expiration))];

        const today = new Date().toISOString().split('T')[0];
        if (!uniqueExpiries.includes(today)) uniqueExpiries.push(today);

        if (!uniqueExpiries.includes('2024-07-05')) uniqueExpiries.push('2024-07-05');
        if (!uniqueExpiries.includes('2024-07-12')) uniqueExpiries.push('2024-07-12');

        setStrikes(uniqueStrikes);
        setExpiries(uniqueExpiries.sort());
        setOptionsData(sortedOptions);

        return sortedOptions;
    } catch (error) {
        console.error('Error fetching options data:', error);
        setOptionsData([]);
        return [];
    }
};