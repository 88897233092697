import React from 'react';
import Banner from '../components/Banner';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './Calls.css';
import AutoCompleteSearch from './AutoCompleteSearch';
import { useCallsHooks } from '/Users/harrisonmcdonald/WebstormProjects/option_shop/src/pages/hooks/useCallsHooks.js';
// ... other imports

const CallsPage = () => {
    const navigate = useNavigate();
    const {
        ticker,
        stockPrice,
        companyOverview,
        historicalData,
        optionsData,
        selectedStrike,
        selectedExpiry,
        strikes,
        expiries,
        filteredCalls,
        filteredPuts,
        currentIndex,
        visibleStrike,
        callOptionsRef,
        putOptionsRef,
        handleSearch,
        handleExpiryClick,
        visibleExpiries,
        handlePrevClick,
        handleNextClick,
    } = useCallsHooks();

    const handleOptionClick = (option) => {
        navigate(`/option-details/${ticker}/${option.strike}/${option.type}/${selectedExpiry}`);
    };

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <Banner />
            <div className="page-content">
                <div className="left-section">
                    <AutoCompleteSearch onSearch={handleSearch} />
                    {stockPrice && (
                        <div className="stock-price">
                            {ticker.toUpperCase()}<br />
                            ${stockPrice}
                            {companyOverview && (
                                <div className="company-overview">
                                    <p>{companyOverview.Name}</p>
                                    <p>{companyOverview.Description}</p>
                                </div>
                            )}
                        </div>
                    )}
                    {historicalData && (
                        <div className="chart-container">
                            <Line data={historicalData} />
                        </div>
                    )}
                    <div className="expiry-container">
                        <button className="nav-button" onClick={handlePrevClick} disabled={currentIndex === 0}>{'<'}</button>
                        {visibleExpiries.length > 0 ? (
                            visibleExpiries.map((expiry, index) => (
                                <div
                                    key={index}
                                    className={`expiry-item ${expiry === selectedExpiry ? 'selected' : ''}`}
                                    onClick={() => handleExpiryClick(expiry)}
                                >
                                    {expiry}
                                </div>
                            ))
                        ) : (
                            <div>No expiries available</div>
                        )}
                        <button className="nav-button" onClick={handleNextClick} disabled={currentIndex + 5 >= expiries.length}>{'>'}</button>
                    </div>
                    <div className="options-container">
                        <div className="smalloptions-container">
                            <h3>Calls</h3>
                            <div className="options-column" ref={callOptionsRef}>
                                {filteredCalls.length > 0 ? (
                                    filteredCalls.map((option, index) => (
                                        <div key={index} className="option-row" onClick={() => handleOptionClick(option)}>
                                            <div>Strike: {option.strike}</div>
                                            <div>Last: {option.last}</div>
                                            <div>Bid: {option.bid}</div>
                                            <div>Ask: {option.ask}</div>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                        <div className="smalloptions-container">
                            <h3>Puts</h3>
                            <div className="options-column" ref={putOptionsRef}>
                                {filteredPuts.length > 0 ? (
                                    filteredPuts.map((option, index) => (
                                        <div key={index} className="option-row" onClick={() => handleOptionClick(option)}>
                                            <div>Strike: {option.strike}</div>
                                            <div>Last: {option.last}</div>
                                            <div>Bid: {option.bid}</div>
                                            <div>Ask: {option.ask}</div>
                                        </div>
                                    ))
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-section">
                    {/* Continue with the rest of the page content */}
                </div>
            </div>
        </div>
    );
};

export default CallsPage;