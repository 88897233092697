import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <div style={{
            background: 'rgba(255, 255, 255, 1)',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                <img src={`${process.env.PUBLIC_URL}/2230606.png`} alt="house icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                <span style={{ fontSize: '24px', fontWeight: 'bold', color: 'rgba(5, 4, 75, 1)' }}>Option Shop</span>
            </Link>
            <nav>
                <Link to="/" style={{ color: 'rgba(5, 4, 75, 1)', margin: '0 10px' }}>Home</Link> |
                <Link to="/data-sourcing" style={{ color: 'rgba(5, 4, 75, 1)', margin: '0 10px' }}>Data Sourcing</Link>
            </nav>
        </div>
    );
};

export default Banner;