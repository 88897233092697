import { useState, useEffect, useRef } from 'react';
import { fetchStockPrice, fetchHistoricalData, fetchOptionsData, fetchCompanyOverview } from '../fetchData';
import { getClosestStrike, getCenteredStrikes, getCenteredOptions } from '../utils';
import { isMarketOpen, getPreviousCloseData } from '../marketUtils';

export const useCallsHooks = () => {
    const [ticker, setTicker] = useState('');
    const [stockPrice, setStockPrice] = useState(null);
    const [companyOverview, setCompanyOverview] = useState(null);
    const [historicalData, setHistoricalData] = useState(null);
    const [optionsData, setOptionsData] = useState([]);
    const [selectedStrike, setSelectedStrike] = useState('');
    const [selectedExpiry, setSelectedExpiry] = useState('');
    const [strikes, setStrikes] = useState([]);
    const [expiries, setExpiries] = useState([]);
    const [filteredCalls, setFilteredCalls] = useState([]);
    const [filteredPuts, setFilteredPuts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleStrike, setVisibleStrike] = useState(null);
    const callOptionsRef = useRef(null);
    const putOptionsRef = useRef(null);

    const handleSearch = async (query) => {
        if (!query) return;

        setTicker(query);
        setSelectedStrike('');
        setSelectedExpiry('');
        setFilteredCalls([]);
        setFilteredPuts([]);

        try {
            const price = await fetchStockPrice(query);
            setStockPrice(price);

            const overview = await fetchCompanyOverview(query);
            setCompanyOverview(overview);

            await fetchHistoricalData(query, setHistoricalData);
            if (isMarketOpen()) {
                const data = await fetchOptionsData(query, setOptionsData, setStrikes, setExpiries);
                const uniqueStrikes = data.map(option => option.strike);
                const uniqueExpiries = [...new Set(data.map(option => option.expiration))];
                setStrikes(uniqueStrikes);
                setExpiries(uniqueExpiries);
                const closestExpiry = getClosestExpiry(uniqueExpiries);
                setSelectedExpiry(closestExpiry);
            } else {
                const previousData = await getPreviousCloseData(query);
                if (previousData) {
                    setOptionsData(previousData.optionsData);
                    setStrikes(previousData.strikes);
                    setExpiries(previousData.expiries);
                    const closestExpiry = getClosestExpiry(previousData.expiries);
                    setSelectedExpiry(closestExpiry);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (ticker) {
                const price = await fetchStockPrice(ticker);
                setStockPrice(price);
            }
        }, 60000); // Fetch every 60 seconds

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [ticker]);

    useEffect(() => {
        if (stockPrice && optionsData.length > 0 && selectedExpiry) {
            const centeredStrikes = getCenteredStrikes(strikes, stockPrice, strikes.length);
            setStrikes(centeredStrikes);

            const closestStrike = getClosestStrike(centeredStrikes, stockPrice);
            if (closestStrike !== null) {
                setSelectedStrike(closestStrike);

                const displayCalls = optionsData
                    .filter(option => option.type === 'call' && option.expiration === selectedExpiry)
                    .sort((a, b) => parseFloat(a.strike) - parseFloat(b.strike));

                const displayPuts = optionsData
                    .filter(option => option.type === 'put' && option.expiration === selectedExpiry)
                    .sort((a, b) => parseFloat(a.strike) - parseFloat(b.strike));

                const centeredCalls = getCenteredOptions(displayCalls, closestStrike, displayCalls.length);
                const centeredPuts = getCenteredOptions(displayPuts, closestStrike, displayPuts.length);

                setFilteredCalls(centeredCalls);
                setFilteredPuts(centeredPuts);
            }
        }
    }, [stockPrice, optionsData, selectedExpiry]);

    useEffect(() => {
        if (filteredCalls.length > 0 && filteredPuts.length > 0) {
            let closestCallIndex;
            let closestPutIndex;
            if (visibleStrike) {
                closestCallIndex = filteredCalls.findIndex(option => option.strike === visibleStrike);
                closestPutIndex = filteredPuts.findIndex(option => option.strike === visibleStrike);
            } else {
                closestCallIndex = filteredCalls.findIndex(option => option.strike === selectedStrike);
                closestPutIndex = filteredPuts.findIndex(option => option.strike === selectedStrike);
            }
            if (closestCallIndex >= 0 && callOptionsRef.current) {
                callOptionsRef.current.scrollTop = closestCallIndex * 80; // Adjust 60 based on the height of each option row
            }
            if (closestPutIndex >= 0 && putOptionsRef.current) {
                putOptionsRef.current.scrollTop = closestPutIndex * 80; // Adjust 60 based on the height of each option row
            }
        }
    }, [filteredCalls, filteredPuts, selectedStrike, visibleStrike]);

    const handleExpiryClick = (expiry) => {
        if (callOptionsRef.current && filteredCalls.length > 0) {
            const scrollTop = callOptionsRef.current.scrollTop;
            const currentVisibleIndex = Math.floor(scrollTop / 60);
            const currentlyVisibleStrike = filteredCalls[currentVisibleIndex]?.strike; // Use currentVisibleIndex to get the visible strike
            setVisibleStrike(currentlyVisibleStrike);
        }
        setSelectedExpiry(expiry);
    };

    const visibleExpiries = expiries.slice(currentIndex, currentIndex + 5);

    const handlePrevClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleNextClick = () => {
        if (currentIndex + 5 < expiries.length) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const getClosestExpiry = (expiries) => {
        const today = new Date();
        return expiries.reduce((prev, curr) => {
            const prevDate = new Date(prev);
            const currDate = new Date(curr);
            return (Math.abs(currDate - today) < Math.abs(prevDate - today) ? curr : prev);
        });
    };

    const getLatestPrice = async (ticker) => {
        const latestPrice = await fetchStockPrice(ticker);
        return latestPrice;
    };

    useEffect(() => {
        if (ticker) {
            getLatestPrice(ticker).then(price => setStockPrice(price));
        }
    }, [ticker]);

    return {
        ticker,
        stockPrice,
        historicalData,
        optionsData,
        selectedStrike,
        selectedExpiry,
        strikes,
        expiries,
        filteredCalls,
        filteredPuts,
        currentIndex,
        visibleStrike,
        callOptionsRef,
        putOptionsRef,
        handleSearch,
        handleExpiryClick,
        visibleExpiries,
        handlePrevClick,
        handleNextClick,
    };
};