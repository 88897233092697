import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import Banner from '../components/Banner';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
);

const OptionDetails = () => {
    const { ticker, strike, type, expiry } = useParams();
    const [priceData, setPriceData] = useState(null);
    const [greeksData, setGreeksData] = useState(null);
    const [optionName, setOptionName] = useState('');

    useEffect(() => {
        const fetchOptionDetails = async () => {
            try {
                const fetchDataForDate = async (date) => {
                    const response = await axios.get('https://www.alphavantage.co/query', {
                        params: {
                            function: 'HISTORICAL_OPTIONS',
                            symbol: ticker,
                            date: date,
                            apikey: '7XOVO2C7YC738UCM' // Your actual API key
                        }
                    });
                    return response.data;
                };

                const today = new Date();
                const dates = [];
                for (let i = 0; i < 30; i++) {
                    const date = new Date(today);
                    date.setDate(today.getDate() - i);
                    dates.push(date.toISOString().split('T')[0]);
                }

                const allDataPromises = dates.map(date => fetchDataForDate(date));
                const allDataResponses = await Promise.all(allDataPromises);

                const optionDetails = allDataResponses.flatMap(data =>
                    data.data.filter(option =>
                        option.strike === strike &&
                        option.type === type &&
                        option.expiration === expiry
                    ).map(option => ({
                        date: option.date,
                        last: option.last,
                        greeks: option.greeks
                    }))
                );

                if (optionDetails.length > 0) {
                    const sortedOptionDetails = optionDetails.sort((a, b) => new Date(a.date) - new Date(b.date));
                    const dates = sortedOptionDetails.map(detail => detail.date);
                    const prices = sortedOptionDetails.map(detail => detail.last);
                    const greeks = sortedOptionDetails[0].greeks;

                    setPriceData({
                        labels: dates,
                        datasets: [
                            {
                                label: `${ticker} ${type.toUpperCase()} ${strike} Price`,
                                data: prices,
                                fill: false,
                                backgroundColor: 'rgba(75,192,192,0.4)',
                                borderColor: 'rgba(75,192,192,1)',
                                pointRadius: 2,
                            },
                        ],
                    });

                    setGreeksData(greeks);
                    setOptionName(`${ticker} ${type.toUpperCase()} ${strike} Exp: ${expiry}`);
                }
            } catch (error) {
                console.error('Error fetching option details:', error);
            }
        };

        fetchOptionDetails();
    }, [ticker, strike, type, expiry]);

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <Banner />
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>{optionName}</h2>
            </div>
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '70%' }}>
                    {priceData && (
                        <div className="chart-container">
                            <Line
                                data={priceData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        zoom: {
                                            pan: {
                                                enabled: true,
                                                mode: 'xy',
                                            },
                                            zoom: {
                                                wheel: {
                                                    enabled: true, // Enable zooming with the mouse wheel
                                                },
                                                pinch: {
                                                    enabled: true, // Enable zooming with touch gestures
                                                },
                                                mode: 'xy',
                                            },
                                        },
                                        tooltip: {
                                            mode: 'index',
                                            intersect: false,
                                        },
                                        legend: {
                                            display: true,
                                            position: 'top',
                                        },
                                    },
                                    scales: {
                                        x: {
                                            type: 'time',
                                            time: {
                                                unit: 'day',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Date',
                                            },
                                        },
                                        y: {
                                            title: {
                                                display: true,
                                                text: 'Price',
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
                <div style={{ width: '30%', padding: '20px' }}>
                    {greeksData && (
                        <div className="greeks-container">
                            <h3>Greeks</h3>
                            <div>Delta: {greeksData.delta}</div>
                            <div>Gamma: {greeksData.gamma}</div>
                            <div>Theta: {greeksData.theta}</div>
                            <div>Vega: {greeksData.vega}</div>
                            <div>Rho: {greeksData.rho}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OptionDetails;