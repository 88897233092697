import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import DataSourcing from './pages/DataSourcing';
import NewPage from './pages/Calls'; // Import the new page
import OptionDetails from './pages/OptionDetails'; // Import the option details page

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/data-sourcing" element={<DataSourcing />} />
                <Route path="/calls" element={<NewPage />} /> {/* Add new page route */}
                <Route path="/option-details/:ticker/:strike/:type/:expiry" element={<OptionDetails />} /> {/* Add option details route */}
            </Routes>
        </Router>
    );
}

export default App;