export const getClosestStrike = (strikeArray, currentPrice) => {
    if (strikeArray.length === 0) {
        return null;
    }
    return strikeArray.reduce((prev, curr) => (
        Math.abs(curr - currentPrice) < Math.abs(prev - currentPrice) ? curr : prev
    ));
};

export const getCenteredStrikes = (strikeArray, currentPrice, numOptions) => {
    const closestStrike = getClosestStrike(strikeArray, currentPrice);
    if (closestStrike === null) {
        return [];
    }
    const nearestIndex = strikeArray.findIndex(strike => parseFloat(strike) === closestStrike);
    const half = Math.floor(numOptions / 2);
    const start = Math.max(0, nearestIndex - numOptions);
    const end = nearestIndex + half + 1;
    return strikeArray.slice(start, end);
};

export const getCenteredOptions = (optionsArray, currentPrice, numOptions) => {
    const closestStrike = getClosestStrike(optionsArray.map(option => parseFloat(option.strike)), currentPrice);
    if (closestStrike === null) {
        return [];
    }
    const nearestIndex = optionsArray.findIndex(option => parseFloat(option.strike) === closestStrike);
    const half = Math.floor(numOptions / 2);
    const start = Math.max(0, nearestIndex - numOptions);
    const end = nearestIndex + half + 1;
    return optionsArray.slice(start, end);
};