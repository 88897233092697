import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Calls.css';

const AutoCompleteSearch = ({ onSearch }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);
    const [dropdownStyles, setDropdownStyles] = useState({});

    useEffect(() => {
        if (query.length > 2) {
            const fetchSuggestions = async () => {
                try {
                    const response = await axios.get('https://www.alphavantage.co/query', {
                        params: {
                            function: 'SYMBOL_SEARCH',
                            keywords: query,
                            apikey: '7XOVO2C7YC738UCM'
                        }
                    });
                    setSuggestions(response.data.bestMatches || []);
                } catch (error) {
                    console.error('Error fetching suggestions:', error);
                }
            };
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [query]);

    useEffect(() => {
        if (inputRef.current) {
            const { bottom, left, width } = inputRef.current.getBoundingClientRect();
            setDropdownStyles({
                position: 'absolute',
                top: `${bottom + window.scrollY - 78}px`,
                left: `${0}px`,
                width: `${width - 2}px`
            });
        }
    }, [query]);

    const handleInputChange = (e) => {
        setQuery(e.target.value);
        setShowSuggestions(true);
    };

    const handleSuggestionClick = (symbol) => {
        setQuery(symbol);
        setShowSuggestions(false);
        onSearch(symbol);
    };

    return (
        <div className="search-form">
            <input
                ref={inputRef}
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Enter stock ticker or company name"
                className="search-input"
            />
            <button type="button" className="search-button" onClick={() => onSearch(query)}>Search</button>
            {showSuggestions && suggestions.length > 0 && (
                <div className="autocomplete-dropdown" style={dropdownStyles}>
                    {suggestions.map((suggestion, index) => (
                        <div
                            key={index}
                            className="autocomplete-item"
                            onClick={() => handleSuggestionClick(suggestion['1. symbol'])}
                        >
                            {suggestion['1. symbol']} - {suggestion['2. name']}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AutoCompleteSearch;