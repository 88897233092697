import React from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '../components/Banner';
import './LandingPage.css'; // Import the CSS file

const LandingPage = () => {
    const navigate = useNavigate();

    const buttonStyle = {
        backgroundColor: '#000050', /* Dark blue from the banner */
        width: '200px',
        height: '80px',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        margin: '5px',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    };

    const buttonHoverStyle = {
        backgroundColor: '#000032', /* Slightly darker blue on hover */
    };

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <Banner />
            <div style={{ textAlign: 'center', marginTop: '25px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '56px' }} className="roboto-thin">
                    <span>Option Shop</span>
                    <img src={`${process.env.PUBLIC_URL}/2230606.png`} alt="house icon" style={{ width: '56px', height: '56px', marginLeft: '20px' }} />
                </div>
                <div style={{ textAlign: 'center', marginTop: '150px' }}>
                    <h1 style={{ fontSize: '48px', marginBottom: '20px' }} className="roboto-thin">
                        Get the real price of an option
                    </h1>
                    <div style={{ fontSize: '18px', marginBottom: '40px' }} className="roboto-thin">
                        Screen stock and option data to find the true price of options.
                    </div>
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: '50px', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }} className="roboto-thin">
                <button
                    className="button roboto-thin"
                    onClick={() => navigate('/calls')}
                    style={buttonStyle}
                    onMouseEnter={e => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor}
                    onMouseLeave={e => e.target.style.backgroundColor = buttonStyle.backgroundColor}
                >
                    Start
                </button>
            </div>
        </div>
    );
};

export default LandingPage;