import axios from 'axios';
import moment from 'moment';

const ALPHA_VANTAGE_API_KEY = '7XOVO2C7YC738UCM';
const ALPHA_VANTAGE_API_URL = 'https://www.alphavantage.co/query';

export const isMarketOpen = () => {
    return false; // Placeholder for market open check logic
};

export const getPreviousCloseData = async (symbol) => {
    try {
        const response = await axios.get(ALPHA_VANTAGE_API_URL, {
            params: {
                function: 'HISTORICAL_OPTIONS',
                symbol: symbol,
                apikey: ALPHA_VANTAGE_API_KEY
            }
        });

        if (response.status === 200) {
            const data = response.data.data;

            if (!data || !Array.isArray(data)) {
                throw new Error("Invalid response data: expected an array of options data");
            }

            const optionsData = data;
            const strikes = [...new Set(optionsData.map(option => option.strike))];
            const expiries = [...new Set(optionsData.map(option => option.expiration))];

            return {
                optionsData,
                strikes,
                expiries
            };
        } else {
            console.error('Error fetching data:', response.status);
            return null;
        }
    } catch (error) {
        console.error('Error fetching data:', error.message);
        return null;
    }
};